/************* Globals *************/
body {
  max-width: 2000px;
  margin: 0 auto;
}

body.dark {
  background: #777777;
}

.section-title {
  font-weight: 700;
  font-size: 3em;
  margin-bottom: 0;
}

.section-title.black {
  color: black;
}

.section-title.white {
  color: white;
}

.dark .section-title.black {
  color: white;
}

/************* Gradients *************/
.blue-back-outer {
  height: 7em;
  width: 100%;
}

.blue-back-inner {
  height: 100%;
  width: 100%;
  background: #4DC8FF;
  transform: skewY(3deg);
  transform-origin: bottom left;
  transition: .5s;
  position: relative;
}

.dark .blue-back-outer{
  background: #777777;
}

.dark .blue-back-inner {
  background: rgb(13, 35, 72);
}

.blue-forward-outer {
  height: 7em;
  width: 100%;
}

.blue-forward-inner {
  height: 100%;
  width: 100%;
  background: #4DC8FF;
  transform: skewY(-3deg);
  transform-origin: bottom left;
  transition: .5s;
  position: relative;
}

.dark .blue-forward-outer {
  background: #777777;
}

.dark .blue-forward-inner {
  background: rgb(13, 35, 72);
}

.lime-back-outer {
  height: 7em;
  width: 100%;
}

.lime-back-inner {
  height: 100%;
  width: 100%;
  background: #C3FFC1;
  transform: skewY(3deg);
  transform-origin: bottom left;
  transition: .5s;
  position: relative;
}

.dark .lime-back-outer {
  background: #777777;
}

.dark .lime-back-inner {
  background: rgb(50, 91, 48);
}

.lime-forward-outer {
  height: 7em;
  width: 100%;
}

.lime-forward-inner {
  height: 100%;
  width: 100%;
  background: #C3FFC1;
  transform: skewY(-3deg);
  transform-origin: bottom left;
  transition: .5s;
  position: relative;
}

.dark .lime-forward-outer {
  background: #777777;
}

.dark .lime-forward-inner {
  background: rgb(50, 91, 48);
}

@media screen and (max-width: 750px) {
  .blue-forward-inner {
    transform: skewY(-10deg);
  }
  .lime-forward-inner {
    transform: skewY(-10deg);
  }
  .blue-back-inner {
    transform: skewY(10deg);
  }
  .lime-back-inner {
    transform: skewY(10deg);
  }
}

/***************  Header ***************/

.header-container {
  width: 100%;
  max-width: 2000px;
  height: 4em;
  top: 0;
  position: fixed;
  z-index: 10;
  border-bottom: solid 1px #4DC8FF;
  display: grid;
  align-items: center;
  grid-template-columns: 33.33% 66.66%;
  background: #fff;
  transition: .5s;
}

.dark .header-container {
  background: #3e3e3e;
  border-bottom: solid 1px rgb(13, 35, 72);
}

.title-container {
  font-size: 2em;
  font-weight: 300;
  margin-left: 3em;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
}

.dark .title {
  color: #fff;
}

.title p {
  margin: 0 0 0 1em;
}

.title img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
}

.title:hover {
  color: rgb(56, 56, 56);
}

.nav-container-desktop {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.ham-menu {
  display: none;
  z-index: 1;
}

.pop-up-nav {
  display: none;
  z-index: 200;
}

.pop-up-nav-items {
  display: none;
}

.navbar {
  display: flex;
  justify-content: space-around;
  width: 45%;
  margin-right: 3em;
  font-size: 20px;
  font-weight: 500;
}

a {
  text-decoration: none;
  color: rgb(56, 56, 56);
  transition: .2s;
}

.dark .navbar a {
  color: #fff;
}

.dark .navbar a:hover {
  color: rgb(139, 139, 139);
}

a:hover {
  color: rgb(139, 139, 139);
}

/***************  Hero Section  ***************/

#top {
  position: absolute;
  top: 0;
}

#home {
  margin-top: 4em;
  background: #4DC8FF;
  height: calc(100vh - 4em);
  transition: .5s;
}

.dark #home {
  background: rgb(13, 35, 72);
}

.container {
  margin: 0 auto;
  width: 100%;
  display: flex;
}

.hero-container {
  width: 50%;
  height: 100%;
}

.hero-container img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.hero-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
  width: 100%;
  color: #fff;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0 10%;
  height: 50%;
}

.hero-title h1 {
  font-size: 3em;
  margin-bottom: 18px;
  text-align: center;
}

.hero-desc-container {
  margin-top: 0px;
  width: 75%;
  font-size: 1.25em;
  text-align: center;
  margin-bottom: 18px;
}

.hero-desc-container-tablet {
  display: none;
}

.hero-desc-container h4 {
  font-weight: 500;
}

.projects-btn {
  margin: 0 0 1em 0;
  background: #C3FFC1;
  width: 207px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  font-weight: bold;
  color: black;
  border-radius: 45px;
  transition: .5s;
}

.dark .projects-btn {
  background: rgb(50, 91, 48);
  color: white;
}

.projects-btn:hover {
  background: rgb(199, 248, 198);
  box-shadow: 0 0 5px 1px #777777;
  color: #3a3a3a;
}

.toggle-container.top {
  opacity: 1;
  transition: .5s;
  z-index: 0;
}

.toggle-container.not-top {
  opacity: 0;
  transition: .5s;
  z-index: -1;
}

.dark-mode-toggle-title {
  margin: 0 1em 0 0;
  color: #fff;
}

.dark-mode-toggle-container {
  height: 1.5em;
  width: 2.5em;
  border-radius: 1.5em;
  background: #777777;
  position: relative;
}

.dark-mode-toggle {
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 0;
}

.icon-container {
  width: 175px;
  display: flex;
  justify-content: space-between;
}

.icon-container img {
  box-shadow: 0 0 0 #777777;
  width: 40px;
  transition: .4s;
}

.icon-container img:hover {
  box-shadow: 0 0 20px lightgray;
  filter: invert(50%);
  border-radius: 15px;
}

a {
  text-decoration: none;
}

.scroll-icon {
  position: relative;
  left: calc(50% - 20px);
  bottom: 100px;
  animation: move .9s alternate;
  animation-delay: 3s;
  animation-iteration-count: 4;
  z-index: 1;
}

.wait {
  animation-name: appear;
  animation-duration: 4s;
  animation-iteration-count: 1;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes move {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(15px);
  }
}

/***************  About Section  ***************/

#about {
  margin-top: 0;
}

.philo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  position: relative;
  transition: .5s;
}

.dark .philo-container {
  background: #777777;
}

.philo-section {
  width: 90%;
  display: flex;
  justify-content: space-around;
  padding: 3% 0 0;
}

.philo {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  padding: 0 3em;
}

.philo-icon {
  align-self: center;
  width: 93px;
  height: 93px;
  transition: .5s;
}

.dark .philo-icon {
  filter: brightness(0) invert(1);
}

.blue-line {
  align-self: center;
  margin-top: 30px;
  height: 3px;
  width: 88px;
  border-radius: 3px;
  background-color: #4DC8FF;
  transition: .5s;
}

.dark .blue-line {
  background: #fff;
}

.philo-text {
  text-align: center;
}

.philo-text h4 {
  color: #777777;
  font-weight: 500;
  font-size: 1.25em;
}

.dark .philo-text h4 {
  color: #fff;
}

.about-text-container {
  width: 100%;
  background: #C3FFC1;
  z-index: 1;
}

.about-text {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #C3FFC1;
  transition: .5s;
}

.dark .about-text {
  background: rgb(50, 91, 48);
  color: #fff;
}

.about-me {
  width: 67%;
  position: relative;
}

.about-me h1 {
  font-weight: 700;
  font-size: 3em;
}

.about-me p {
  text-align: left;
  font-weight: 400;
  font-size: 1.25em;
}

/***************  Project Section  ***************/

.projects {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dark .projects {
  background: #777777;
  color: #fff;
}

.dark .projects a {
  color: #fff;
}

#projects {
  position: relative;
}

.projects-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-title {
  font-weight: 700;
  font-size: 3em;
}

.project-blue-line {
  margin-top: 5px;
  margin-bottom: 45px;
  height: 3px;
  width: 88px;
  border-radius: 3px;
  background-color: #4DC8FF;
  transition: .5s;
}

.dark .project-blue-line {
  background: #fff;
}

.projects-container {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  grid-template-rows: repeat(2, 50%);
}

.project-picture-container {
  margin: 0 auto;
  margin-bottom: 15px;
  height: 260px;
  width: 260px;
  background: linear-gradient(126deg, rgba(0, 212, 255, 1) 0%, rgba(195, 255, 193, 1) 100%);
  border-radius: 20px;
  transition: .5s;
  position: relative;
}

.image-overlay-message {
  transition: .3s;
  position: absolute;
  top: 0;
  height: 260px;
  width: 260px;
  border-radius: 20px;
  background: black;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.25em;
}

.image-overlay-message:hover {
  opacity: .75;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0 0 15px 1px rgb(224, 224, 224);
  transition: .5s;
}

.dark .project-image {
  box-shadow: 0 0 15px 1px #3f3f3f
}

.project-description {
  margin: 0 auto;
  margin-bottom: 35px;
  width: 90%;
  text-align: center;
}

.project-description h2 {
  margin: .8em auto .25em;
}

.project-description p {
  margin: 0 auto;
  width: 75%;
}

.project-demo-container {
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, .75);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.project-demo-fullscreen-close {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: default;
}

.project-demo-title {
  width: 70%;
  color: white;
  text-align: center;
  position: relative;
}

.project-demo-title h1 {
  margin: 0 0 .5em;
}

.project-video-container {
  position: relative;
  width: 70%;
  z-index: 2;
}

.demo-close {
  width: 40px;
  position: absolute;
  top: 3px;
  transform: rotate(45deg);
  left: 0;
  filter: invert(1);
  z-index: 2;
}

/***************  Contact Section  ***************/

.contact {
  width: 100%;
  height: 250px;
  background: #4DC8FF;
  display: flex;
  justify-content: center;
  transition: .5s;
}

.dark .contact {
  background: rgb(13, 35, 72);
}

.contact-container {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-title {
  font-weight: 700;
  font-size: 3em;
  color: white;
  margin-bottom: 0;
}

.email {
  margin: 0 auto .75em;
  color: white;
  font-size: 2em;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
}

input {
  margin: 0 auto 1em;
  width: 50%;
  font-size: 20px;
  padding: 5px;
  border: none;
  border-radius: 1px;
}

textarea {
  margin: 0 0 1em;
  width: 75%;
  font-size: 20px;
  padding: 5px;
  border: none;
  resize: none;
  border-radius: 1px;
}

.contact-btn {
  margin: 0 auto;
  margin-top: 15px;
  background: #C3FFC1;
  width: 40%;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  font-weight: bold;
  color: black;
  border-radius: 45px;
}

/***************  Footer  ***************/

.footer {
  width: 100%;
  position: relative;
  bottom: 5em;
  height: 8em;
  background: #4DC8FF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: .5s;
}

.dark .footer {
  background: rgb(13, 35, 72);
}

.footer-block {
  background: #4DC8FF;
  transition: .5s;
}

.dark .footer-block {
  background: rgb(13, 35, 72);
}

.copyright {
  position: relative;
  top: 3.5em;
  color: #fff;
  font-weight: 500;
}

.footer-icon-container {
  position: relative;
  top: 3em;
  width: 175px;
}

.footer-scroll-icon {
  position: relative;
  top: 60px;
  transform: rotate(180deg);
}

/***************  Media Queries  ***************/

@media screen and (max-width: 1500px) {
  .title-container {
    font-size: 1.5em;
    position: relative;
  }

  .contact-container {
    width: 30%;
  }
}

@media screen and (max-width: 1024px) {
  .App {
    overflow-x: hidden;
  }

  #home {
    height: calc(100vh - 4em);
  }

  .title-container {
    min-width: 500px;
    font-size: 1.55em;
    margin-left: 1em;
  }

  .navbar {
    width: 50%;
    margin-right: 1em;
  }

  .nav-container-desktop {
    display: none;
  }

  .ham-menu {
    display: flex;
    justify-content: flex-end;
    margin-right: 1.5em;
  }

  .ham-menu img {
    max-width: 35px;
    max-height: 35px;
  }

  .pop-up-nav {
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    border: 1px solid #777777;
    width: 250px;
    background: #fff;
    z-index: 2;
    font-size: 1.5em;
    transition: .3s;
  }

  .dark .pop-up-nav {
    background: #3e3e3e;
    border: solid 1px rgb(13, 35, 72);
  }

  .close-container img {
    max-width: 35px;
    max-height: 35px;
    transform: rotate(45deg);
    padding-top: 1em;
    margin-left: 20px;
  }

  .dark .close-container img {
    filter: invert(1)
  }

  .pop-up-nav-items {
    height: 100px;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .pop-up-nav-items a {
    min-width: 100%;
    padding-top: 1em;
    align-self: center;
  }

  .dark .pop-up-nav-items a {
    color: #fff;
  }

  .container {
    flex-direction: column;
  }

  .projects-btn {
    margin-top: 0px;
    margin-bottom: 40px;
    background: #C3FFC1;
    width: 175px;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25em;
    font-weight: bold;
    color: black;
    border-radius: 45px;
  }

  .icon-container img {
    box-shadow: 0 0 0 #777777;
    width: 40px;
    transition: .4s;
  }

  .icon-container img:hover {
    box-shadow: 0 0 0 #777777;
    filter: invert(0%);
    border-radius: 0;
  }

  .scroll-icon {
    display: none;
  }

  .philo-section {
    width: 90%;
  }

  .philo {
    width: 30%;
    padding: 0 1.25em;
  }

  .philo-icon {
    width: 70px;
    height: 70px;
  }

  .blue-line {
    width: 50px;
  }

  .philo-text h4 {
    width: 100%;
    font-weight: 400;
    font-size: 1.25em;
  }

  /* #about {
        height: 950px;
    } */
  .about-me {
    top: initial;
  }

  .about-me h1 {
    font-weight: 700;
    font-size: 3em;
    margin-bottom: 0;
  }

  .projects {
    padding: 0;
  }

  .projects-page-container {
    margin-bottom: 1%;
  }

  .project-blue-line {
    height: 3px;
    width: 88px;
    border-radius: 3px;
    background-color: #4DC8FF;
  }

  .projects-container {
    width: 93%;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(3, 33.33%);
  }

  .project-picture-container {
    height: 280px;
    width: 280px;
    background: linear-gradient(126deg, rgba(0, 212, 255, 1) 0%, rgba(195, 255, 193, 1) 100%);
    border-radius: 20px;
    transition: .5s;
  }

  .image-overlay-message {
    height: 280px;
    width: 280px;
  }

  .project-demo-title {
    width: 80%;
  }

  .project-video-container {
    width: 80%;
  }

  .project-description {
    width: 80%;
    text-align: center;
    margin-bottom: 2em;
  }

  .project-description h2 {
    font-size: 1.65em;
  }

  .project-description p {
    font-size: 1.25em;
    width: 90%;
  }

  .contact-container {
    padding-top: 25px;
    width: 60%;
  }

  input {
    font-size: 30px;
  }

  textarea {
    font-size: 30px;
  }

  .footer {
    height: 7em;
  }
}

@media screen and (max-width: 750px) {
  .title p {
    display: none;
  }

  .hero-text-container {
    height: 100vh;
  }

  .text-container {
    padding: 0 10%;
  }

  .projects-btn {
    margin: 0 0 40px;
    background: #C3FFC1;
    width: 175px;
    min-height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3em;
    font-weight: bold;
    color: black;
    border-radius: 45px;
  }

  .icon-container {
    margin-top: 0em;
  }

  .philo-section {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 7em 0 5em;
  }

  .philo {
    min-width: 75%;
  }

  .about-me {
    width: 85%;
    text-align: center;
    top: -25px;
  }

  .about-me h1 {
    font-weight: 700;
    font-size: 2.5em;
    margin-bottom: 0;
  }

  .about-me p {
    text-align: center;
  }

  .projects {
    padding: 3em 0;
  }

  .projects-container {
    width: 93%;
    display: flex;
    flex-direction: column;
  }

  .project-container {
    text-align: center;
  }

  .project-demo-title h1 {
    color: rgba(0, 0, 0, 0);
  }

  .image-overlay-message {
    height: 230px;
    width: 230px;
  }

  .project-picture-container {
    max-height: 230px;
    max-width: 230px;
    background: linear-gradient(126deg, rgba(0, 212, 255, 1) 0%, rgba(195, 255, 193, 1) 100%);
    border-radius: 20px;
    transition: .5s;
  }

  .project-description h2 {
    font-size: 35px;
  }

  .project-description p {
    font-size: 1.25em;
  }

  .contact {
    height: 250px;
    width: 100%;
    padding: 10% 0 0;
  }

  .contact-container {
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: .75em;
    display: inherit;
  }

  .contact-container p {
    font-size: 1.75em;
  }
}

@media screen and (max-width: 600px) {
  .hero-text-container {
    height: 60vh;
  }

  .text-container {
    font-size: .8em;
  }
}